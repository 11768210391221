<template>
    <div class="GlobalFormDemo">
        <GlobalForm :init-data="initData" :needBtnLoading="true" :form-item-list="formItemList" :inline="true"
            :labelWidth="90" round @handleConfirm="handleConfirm" confirmBtnName="查询">
            <el-form-item class="el_from" label="门店商户" slot="shop" style="margin:0">
                <el-select v-model="shop" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="原始订单号" slot="orderNo" style="margin:0">
                <el-input v-model="orderNo" clearable placeholder="请输入原始订单号查询"></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="账单类型" slot="orderNo">
                <el-select v-model="opt1" placeholder="请选择账单类型查询"  clearable>
                    <el-option v-for="item in optList1" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="账单子类型" slot="orderNo">
                <el-select v-model="opt" placeholder="请选择账单类型查询" filterable clearable>
                    <el-option v-for="item in optList" :key="item.key" :label="item.value"
                        :value="item.key"></el-option>
                </el-select>
            </el-form-item>
        </GlobalForm>
    </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import { _http } from "../../../utils/request/bill";
export default {
    name: "Form",
    props: {
    },
    data() {
        return {
            SeachParams: {},
            startTime: "",
            endTime: "",
            day60: 30 * 24 * 3600 * 1000,
            initData: {
                entryTime: [
                moment().day(moment().day() - 30).format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')
                ],
            },
            companyId: "",
            storeId: "",
            orderNo: "",
            opt: "",
            opt1:"",
            serTypeList: [],
            serType: "",
            isHasCompany: true,
            formItemList: [
                { slotName: 'shop' },
                {
                    key: "entryTime",
                    type: "pickerOptions",
                    labelName: "账单时间",
                    lableWidth: '90',
                    clearable: true,
                    placeholder: "请选择时间",
                    valueFormat: "timestamp",
                    pickerOptions: {
                        onPick: ({ maxDate, minDate }) => {
                            if (minDate && this.pickerMinDate) {
                                this.pickerMinDate = null;
                            } else if (minDate) {
                                this.pickerMinDate = minDate.getTime();
                            }
                        },
                        disabledDate: (time) => {
                            if (this.pickerMinDate) {
                                return (
                                    time.getTime() > this.pickerMinDate + this.day60 ||
                                    time.getTime() < this.pickerMinDate - this.day60
                                );
                            }
                            return false;
                        },
                    },
                },
                { slotName: 'operationSelect' },
                {
                    key: "uniqNo",
                    type: "input",
                    labelName: "账单流水号",
                    placeholder: "请输入账单流水号查询",
                },
                { slotName: 'orderNo' },
                { slotName: 'opt' },
                { slotName: 'serTypeList' },
            ],
            optList: [],
            shop: "",
            shopList: [],
            optList1: [
                {
                    value: '01',
                    label: '入账',
                },
                {
                    value: '02',
                    label: '出账',
                },
                {
                    value: '03',
                    label: '挂账',
                },
            ],
        };
    },
    created() {
        this.shop = Number(this.$route.query.companyId) || ""
        console.log(this.$route.query.companyId,"this.$route.query.companyId")
        this.$store.commit("tagsView/ROUTER_PATH", this.$route.path);
        this.ClearSeachParams();
        _api.getKACompanyList().then((res) => {
            if (res.code === 1) {
                this.shopList = res.data  
            }
        })
        _api.getSerTypeList({ accountType: "companyRedActivity" }).then((res) => {
            if (res.code === 1) {
                this.optList = res.data
            }
        })
        this.handleConfirm(this.initData);
    },
    watch: {
        companyId(newVal) {
            console.log(newVal);
            if (newVal != '') {
                this.isHasCompany = false
            } else {
                this.storeId = ''
                this.isHasCompany = true
            }
        },
        shop(val) {
            if (val != "") {
                this.getCompanyAccount()
            }
            if (!val) {
                console.log(1234568)
                this.$emit("showBalance", 0, false)
            }
        }
    },
    methods: {
        handleConfirm(data, cd) {
            console.log("提交了form", data);
            console.log(this.opt1,"thi")
            if (!Array.isArray(data.entryTime)) {
                (this.startTime = null), (this.endTime = null);
            } else {
                (this.startTime = moment(data.entryTime[0]).format("x")),
                    (this.endTime = moment(data.entryTime[1]).format("x"));
            }
            let SeachParams = {};
            if (this.orderNo) {
                SeachParams = {
                    orderNo: this.orderNo
                };
            }
            if (data) {
                SeachParams = {
                    uniqNo: data.uniqNo || "",
                    orderNo: this.orderNo || data.orderNo || "",
                    serType: this.opt || "",
                    startTime: this.startTime,
                    endTime: this.endTime,
                    pageNum: 1,
                    pageSize: 10,
                    companyId: this.shop,
                    opt:this.opt1
                };
            }
            SeachParams.disuseLoding = () => {
                if (typeof cd === 'function') {
                    cd();
                }
            },
                this.SeachParams = SeachParams
            console.log(SeachParams);
            this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
        },
        getCompanyAccount() {
            _http.dispatchGetCompanyAccount({ companyId: this.shop }).then(res => {
                if (res.code == 1) {
                    console.log(res.data.companyAccount.redEnvelopeActivityBalance, "res.data.companyAccount.redEnvelopeActivityBalance")
                    this.$emit("showBalance", res.data.companyAccount.redEnvelopeActivityBalance, true)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        ClearSeachParams() {
            this.$store.dispatch("tagsView/ClearSeachParams", "");
        },
    },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>